
import { Component, OnInit, ViewChild } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './app-dialog.component.html',
  styleUrls: ['./app-dialog.component.scss']
})
export class AppDialogComponent implements OnInit {

  dialogData: any;
  @ViewChild('modalDefault0', { static: false }) public PolicyStatusModal0: any;
  constructor(private dialogConfig: DynamicDialogConfig, public ref: DynamicDialogRef) { }

  ngOnInit() {
    // console.log('Dialog Config: ', this.dialogConfig);
    this.dialogData = this.dialogConfig.data;
  }

  closeDialog() {
    this.ref.close();
  }
}
