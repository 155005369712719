import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AppService } from '../app.services';
import { ToastrService } from 'ngx-toastr';
// import { finalize } from 'rxjs/operators';
import { interval } from 'rxjs';
import { finalize, take, takeWhile } from "rxjs/operators";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthConfig, AuthConfig1 } from '../app-config';
import { Loading } from 'notiflix';
import * as Notiflix from 'notiflix';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  loginForm: FormGroup;
  forgetPassForm: FormGroup;
  otpForm: FormGroup;
  fieldTextType: boolean;
  loading: boolean;
  forgetpassword = false;
  loginDiv = true;
  verifyotp = false;
  userName: string;
  reqBody :{
    userName: string,
    otp: string
  }
  reqBody1: { userName: string };
  loading1: boolean;
  disableResend: boolean;
  startTimer: boolean;
  OTPTimer: number;

  constructor(private _http: HttpClient, private _appService: AppService, private router: Router, private toastr: ToastrService) { 
    Loading.dots();
    if(sessionStorage.getItem('access_token')!==null){
      this.loginbytoken()
    }
  }

  ngOnInit() { 
    this.loginForm = new FormGroup({
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
      elastic: new FormControl(null, null)
    });
    this.forgetPassForm = new FormGroup({
      userName: new FormControl('', Validators.required)
    });
    this.otpForm = new FormGroup({
      // userName: new FormControl('', Validators.required),
      otp: new FormControl('', Validators.required)
    });

      // var url = window.location.href;
      // console.log(url);
      // var urlArr = url.split("/");
      // var urlArrLen = urlArr.length;
      // var params = urlArr[urlArrLen-1].split('&');    
      // if(params.length>0){
      // sessionStorage.setItem('CORE_SESSION', atob(params[0]));
      // sessionStorage.setItem('refresh_token', atob(params[1]));
      // sessionStorage.setItem('exp', atob(params[2]));
      // sessionStorage.setItem('logouturl', atob(params[3]));
      // // this.cookieService.set('logouturl', atob(params[3]));
      // this.router.navigate(['/admin/dashboard']);
      // }

  }
  
  showDiv(div){
    if(div == 'forgetpassword'){
      this.forgetpassword = true;
      this.loginDiv = false;
      this.verifyotp = false;
    }
    if(div == 'loginDiv'){
      this.forgetpassword = false;
      this.loginDiv = true;
      this.verifyotp = false;
    }    
  }

  loginbytoken(){
    Notiflix.Loading.remove();
    let a=sessionStorage.getItem('access_token')
    // this.toastr.success('Logged in succesfully', '', { positionClass: 'toast-top-left' });
    sessionStorage.setItem('CORE_SESSION', a);
    this.router.navigate(['/admin/dashboard']); 
    let i=1;
    sessionStorage.setItem('counter',i.toString())
  }

  login() {
    this.loading = true;
    let params = new URLSearchParams();   
    params.set('grant_type','password');
    params.set('username', this.loginForm.get('username').value);
    params.set('password', this.loginForm.get('password').value);
    // params.set('username', 'IPPBdemo555');
    // params.set('password', 'Test@123');
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Basic `+ btoa('isu-client:isu-password')
    });
    let options = { headers: headers }; 
    this._http.post('https://oauth2-auth-server-oauthjwt-uat-twdwtabx5q-el.a.run.app/oauth/token', params.toString(), options)
    .pipe(finalize(() => this.loading = false))
    .subscribe(
    async (res: any) => {  
      // sessionStorage.setItem('access_token', res.access_token);
      // sessionStorage.setItem('refresh_token', res.refresh_token);
      // sessionStorage.setItem('adminName', res.adminName);      
      // this.toastr.success('Logged in succesfully', '', { positionClass: 'toast-top-left' });
      // this.router.navigate(['../admin/dashboard']);
      sessionStorage.setItem('CORE_SESSION', res.access_token);
      sessionStorage.setItem('Previlege',JSON.stringify(res.privileges));
      sessionStorage.setItem('User_Type',JSON.stringify([res.token_type]));
      this.router.navigate(['/admin/dashboard']);
      let i=1;
      sessionStorage.setItem('counter',i.toString())            
    },
    error => {
      if (error.error.status == -1) {
        console.log('Error Received');
      }
      this.toastr.error('Invalid Credentials', '', { positionClass: 'toast-top-left' });
    }
    );

    // this._appService.clientLogin(this.loginForm.value)
    //   .pipe(finalize(() => this.loading = false))
    //   .subscribe(
    //     (res: any) => {
    //       if (res.token) {
    //         sessionStorage.setItem('CORE_SESSION', res.token);
    //         sessionStorage.setItem('adminName', res.adminName);
    //         this.router.navigate(['/admin/dashboard']);
    //         this.toastr.success('Logged in succesfully', '', { positionClass: 'toast-top-left' });
    //       }
    //     },
    //     error => {
    //       if (error.error.status == -1) {
    //         console.log('Error Received');
    //       }
    //       this.toastr.error('Invalid Credentials', '', { positionClass: 'toast-top-left' });
    //     }
    //   );
    // this.router.navigate(['/admin/dashboard']);
    // this.router.navigate(['/user/dashboard']);
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  async forgotpassword(){
    this.loading = true;
    // let url= await AuthConfig1.config.encodeUrl('https://user-registration-uat-stag.iserveu.tech/utility/send-forgot-password-otp',this.forgetPassForm.value) //Staging
    let url= await AuthConfig1.config.encodeUrl('https://user-registration-prod.iserveu.tech/utility/send-forgot-password-otp',this.forgetPassForm.value) //Prod
    this._appService.sendForgotPpasswordOtp(url,{})
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        (res: any) => {
          if (res.statusCode==0) {
            this.startOTPTimer();
            sessionStorage.setItem('userName', this.forgetPassForm.get('userName').value);
            this.verifyotp = true;
            this.forgetpassword = false;
            this.toastr.success(res.statusDesc, '', { positionClass: 'toast-top-left' });
          }else{
            this.verifyotp = false;
            this.forgetpassword = true;
            this.toastr.error(res.statusDesc, '', { positionClass: 'toast-top-left' });
          }
        },
        error => {
          // if (error.error.status == -1) {
          //   console.log('Error Received');
          // }
          this.verifyotp = false;
          this.forgetpassword = true;
          if(error.error.statusCode=='-5'){
            this.toastr.error(error.error.statusDesc, '', { positionClass: 'toast-top-left' });
          }else{
            this.toastr.error('Something went Wrong! Please try again.', '', { positionClass: 'toast-top-left' });
          }
        }
      );
  }
  async resendOtp(){
    this.loading1 = true;
    const userNameinSession = sessionStorage.getItem('userName');
    this.reqBody1= {
      userName: userNameinSession
    }
    let url= await AuthConfig1.config.encodeUrl('https://user-registration-uat-stag.iserveu.tech/utility/send-forgot-password-otp',this.forgetPassForm.value)
    this._appService.sendForgotPpasswordOtp(url,{})
      .pipe(finalize(() => this.loading1 = false))
      .subscribe(
        (res: any) => {
          if (res.statusCode==0) {
            this.startOTPTimer();
            sessionStorage.setItem('userName', this.forgetPassForm.get('userName').value);
            this.verifyotp = true;
            this.forgetpassword = false;
            this.toastr.success(res.statusDesc, '', { positionClass: 'toast-top-left' });
          }
        },
        error => {
          if(error.error.statusCode=='-5'){
            this.toastr.error(error.error.statusDesc, '', { positionClass: 'toast-top-left' });
          }else{
            this.toastr.error('Something went Wrong! Please try again.', '', { positionClass: 'toast-top-left' });
          }
        }
      );
  }
  verifyOtp(){
    this.loading = true;
    const userNameinSession = sessionStorage.getItem('userName');
    const otp = this.otpForm.get('otp').value;
    this.reqBody= {
      userName: userNameinSession, 
      otp: otp
    }
    this._appService.forgotPasswordSendTemporaryPassword(this.reqBody)
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        (res: any) => {
          if (res.statusCode==0) {
            this.loginDiv = true;
            this.verifyotp = false;
            this.forgetpassword = false;
            this.toastr.success(res.statusDesc, '', { positionClass: 'toast-top-left' });
          }
          if(res.statusCode==-1){
            this.verifyotp = true;
            this.forgetpassword = false;
            this.toastr.error(res.statusDesc, '', { positionClass: 'toast-top-left' });
          }
        },
        error => {
          // if (error.error.status == -1) {
          //   console.log('Error Received');
          // }
          this.toastr.error('Something went Wrong! Please try again.', '', { positionClass: 'toast-top-left' });
        }
      );
  }

  startOTPTimer() {
    this.disableResend = true;
    this.startTimer = true;
    const timerStarts = interval(1000);
    const waitSeconds = 31;
    timerStarts.pipe(take(waitSeconds), takeWhile(() => {
      this.OTPTimer = 30;
      return this.startTimer;
    }) // It will unsubscribe when the "startTimer" will be false.   
    ).subscribe(
      timer => {
        this.OTPTimer = 30 - timer;
        if (this.OTPTimer === 0) {
          this.disableResend = false;
          this.startTimer = false;
        }
      }
    );
  }
}


