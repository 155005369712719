import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbButtonsModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { AngularFireModule } from '@angular/fire';
import { AppRoutingModule } from './app.routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { AdminModule } from './admin/admin.module';
import { SharedModule } from './shared/shared.module';
import { NavigationItem } from './admin/navigation/navigation';
import { StoreModule } from '@ngrx/store';
import { dashboardReducer } from './store/reducer';
import { AuthInterceptor } from './auth.interceptor';
import { ErrorInterceptor } from './error.interceptor';
import { FirebaseConfig } from 'src/app/app-config';
// import { TestComponent } from './admin/test/test.component';
import { TableModule } from 'primeng/table';
import {DialogModule} from 'primeng/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from "@angular/material/checkbox";
// import { NumberToWordsPipePipe } from './shared/pipes/number-to-words-pipe.pipe';
// import { TestReportComponent } from './admin/test/test-report-data/test-report-data.component';

@NgModule({
  declarations: [
    AppComponent
    // TestComponent,
    // TestReportComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AuthModule,
    AdminModule,
    HttpClientModule,
    SharedModule,
    BrowserAnimationsModule,
    NgbButtonsModule,
    NgbTooltipModule,
    ToastrModule.forRoot(),
    StoreModule.forRoot({ dashboard: dashboardReducer }),
    AngularFireModule.initializeApp(FirebaseConfig.config),
    TableModule,
    DialogModule,
    ReactiveFormsModule,
    MatCheckboxModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    NavigationItem

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
