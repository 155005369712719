import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthComponent } from './auth/auth.component';
import { AdminComponent } from './admin/admin.component';

import { AuthGuard } from './guards/auth.guard';
import { UnauthorizedComponent } from './shared/components/unauthorized/unauthorized.component';
// import { TestComponent } from './admin/test/test.component';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./admin/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'settings',
        loadChildren: () => import('./admin/settings-page/settings.module').then(module => module.SettingsModule)
      },
      {
        path: 'add-bank',
        loadChildren: () => import('./admin/settings-page/wallet-top-up/wallet-top-up.module').then(module => module.WalletTopUpModule)
      },
      {
        path: 'forms',
        loadChildren: () => import('./admin/form-elements/form-elements.module').then(m => m.FormElementsModule)
      },
      {
        path: 'transaction',
        loadChildren: () => import('./admin/reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'editProfile',
        loadChildren: () => import('./admin/support-func-new/profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: 'addpin',
        loadChildren: () => import('./admin/support-func-new/add-pin/add-pin.module').then(m => m.AddPinModule)
      },
      {
        path: 'feature',
        loadChildren: () => import('./admin/support-func-new/feature/feature.module').then(m => m.FeatureModule)
      },
      {
        path: 'userFunction',
        loadChildren: () => import('./admin/user-function/user-function.module').then(m => m.UserFunctionModule)
      },
      {
        path: 'transactionfeature',
        loadChildren: () => import('./admin/support-func-new/transactionfeature/transactionfeature.module').then(m => m.TransactionfeatureModule)
      },
      {
        path: 'smsgateway',
        loadChildren: () => import('./admin/smsgateway/smsgateway.module').then(m => m.SmsgatewayModule)
      },
      {
        path: 'manualbankadd',
        loadChildren: () => import('./admin/manualbankadd/manualbankadd.module').then(m => m.ManualbankaddModule)
      },
      // {
      //   path: 'feature',
      //   loadChildren: () => import('./admin/feature/feature.module').then(m => m.FeatureModule)
      // },
      {
        path: 'updatePincode',
        loadChildren: () => import('./admin/add-pin/add-pin.component.module').then(m => m.AddPinModule)
      },
      {
        path: 'transactionStatus',
        loadChildren: () => import('./admin/tran-status/tran-status.module').then(m => m.TransactionStatusModule)
      },
      {
        path: 'resetpassword',
        loadChildren: () => import('./admin/resetpassword/resetpassword.module').then(m => m.ResetpasswordModule)
      },

      {
        path: 'test',
        loadChildren: () => import('./admin/test/test.module').then(m => m.TestModule)
      },
      
      {
        path: 'exotel-partner',
        loadChildren: () => import('./admin/exotel-partner/exotel-partner.module').then(module => module.ExotelPartnerModule)
      },
      
      {
        path: 'escalation',
        loadChildren: () => import('./admin/exotelnewdesign/exotelnewdesign.module').then(module => module.ExotelnewdesignModule)
      },
      // {
      //   path: 'active',
      //   loadChildren: () => import('./admin/support-func-new/active-inactive.module').then(module => module.SupportFuncNewModule)
      // },   
      {
        path: 'support-func-new',
        loadChildren: () => import('./admin/support-func-new/support-func-new.module').then(module => module.SupportFuncNewModule)
      },

      {
        path:'support-func',
        loadChildren: () => import('./admin/support-func/support-func.module').then(module => module.SupportFuncModule)
      },
      {
        path:'member',
        loadChildren: () => import('./admin/member/member.module').then(module => module.MemberModule)
      },
      {
        path:'notification',
        loadChildren: () => import('./admin/notification/notification.module').then(module => module.NotificationModule)
      },
      {
        path:'adv-retailer',
        loadChildren: () => import('./admin/adv-retailer/adv-retailer.module').then(module => module.AdvRetailerModule)
      },
     
      {
        path:'advertise-retailer',
        loadChildren: () => import('./admin/advertise-retailer/advertise-retailer.module').then(module => module.AdvertiseRetailerModule)
      },

      {
        path:'upload-banner',
        loadChildren: () => import('./admin/upload-banner/upload-banner.module').then(module => module.UploadBannerModule)
      },{

        path: 'balance',
        loadChildren: () => import('./admin/balance/balance.module').then(m => m.BalanceModule)
      },
      {
        path: 'productManage',
        loadChildren: () => import('./admin/centralizeManage/centralizeproduct-manage/centralizeproduct-manage.module').then(m => m.CentralizeproductManageModule)
      },
      {
        path: 'commononboard',
        loadChildren: () => import('./admin/common-onboarding/common-onboarding.module').then(m => m.CommonOnboardingModule)
      }

    ]
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  // {
  //   path: 'test',
  //   component: TestComponent
  // },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,{ useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
