import { Component, DoCheck, OnInit, Input } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { animate, style, transition, trigger } from '@angular/animations';
import { DattaConfig } from 'src/app/app-config';
import { AutoLogOutService } from 'src/app/autologout.service';
import { AdminService } from '../../admin.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig],
  animations: [
    trigger('slideInOutLeft', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(100%)' }))
      ])
    ]),
    trigger('slideInOutRight', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class NavRightComponent implements OnInit, DoCheck {
  public visibleUserList: boolean;
  public chatMessage: boolean;
  public friendId: boolean;
  public dattaConfig: any;
  userData:any=JSON.parse(sessionStorage.getItem('userData'));
  userImg: string = '';
  userBalance = 0;
  userBalance2 = 0;
  unsub = new Subject();

  constructor(config: NgbDropdownConfig, private autologoutservice: AutoLogOutService, private admin_service: AdminService, private ngxspinnerservice: NgxSpinnerService) {
    config.placement = 'bottom-right';
    this.visibleUserList = false;
    this.chatMessage = false;
    this.dattaConfig = DattaConfig.config;
  }

  ngOnInit() {
    // this.userImg = `https://firebasestorage.googleapis.com/v0/b/iserveumainapp.appspot.com/o/${this.userData.userInfo.userName}%2Fprofile.png?alt=media&token=5f72bbaa-679c-4c3d-aa37-0b72df64b0c3` || 'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=64';
    // fetch(`https://firebasestorage.googleapis.com/v0/b/iserveumainapp.appspot.com/o/${this.userData.userInfo.userName}%2Fprofile.png?alt=media&token=5f72bbaa-679c-4c3d-aa37-0b72df64b0c3`)
    // .then(res => res.json())
    // .then(jsonData => {
    //   console.log('JSON Data: ', jsonData)
    // })
    // .then((res: any) => {
    //   console.log('Response: ', res);
    //   console.log('JSON Response: ', res.json());
    //   if (res.error.code !== 404) {
    //     console.log('Image is present.');
    //   } else {
    //     console.log('Image is not present');
    //   }
    // })
    // .catch(err => console.log('Error: ', err))
    this.admin_service.walletBalance.subscribe(
      val => {
        console.log('Wallet Balance in Navbar: ', val);
        this.userBalance = val;
      });

    this.admin_service.userBalance2
    .pipe(takeUntil(this.unsub))
    .subscribe(
      val => {
        console.log('Wallet 2 Balance in Navbar: ', val);
        this.userBalance2 = val;
      }
    );
  }

  /* Fetch New Wallet Balance */
  fetchWalletBalance(){
    this.ngxspinnerservice.show("new-wallet-spinner", {
      bdColor: "rgba(0, 0, 0, 0.5)",
      type: "timer"
    });
    this.admin_service.fetchNewWalletBalance();
  }

   /* Fetch New Wallet2 Balance */
   fetchWalletBalance2(){
    this.ngxspinnerservice.show("wallet2Spinner", {
      bdColor: "rgba(0, 0, 0, 0.5)",
      type: "timer"
    });
    // this.admin_service.fetchWallet2Blanace();
  }

  onChatToggle(friend_id) {
    this.friendId = friend_id;
    this.chatMessage = !this.chatMessage;
  }

  ngDoCheck() {
    if (document.querySelector('body').classList.contains('datta-rtl')) {
      this.dattaConfig['rtl-layout'] = true;
    } else {
      this.dattaConfig['rtl-layout'] = false;
    }
  }

  logoutSession() {
    this.autologoutservice.logout();
  }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }
}
