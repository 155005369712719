<ul class="navbar-nav">
  <!--<li appToggleFullScreen><a href="javascript:" class="full-screen"><i class="feather icon-maximize"></i></a></li>-->
  <!-- <li class="nav-item dropdown" ngbDropdown placement="auto">
    <a href="javascript:" data-toggle="dropdown" ngbDropdownToggle class="customClass">Dropdown</a>
    <ul class="dropdown-menu" ngbDropdownMenu>
      <li><a class="dropdown-item" href="javascript:">Action</a></li>
      <li><a class="dropdown-item" href="javascript:">Another action</a></li>
      <li><a class="dropdown-item" href="javascript:">Something else here</a></li>
    </ul>
  </li> -->
  <!-- <li class="nav-item">
    <app-nav-search></app-nav-search>
  </li> -->
</ul>
