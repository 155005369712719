import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  _featureId : string,
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}


const sidebarContent = {
  iserveu: [
    {
      id: 'navigation',
      title: 'Navigation',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: 'dashboard',
          title: 'Dashboard',
          type: 'item',
          url: '/admin/dashboard',
          icon: 'feather icon-home',
          classes: 'nav-item',
        },
        {
          id: 'reports',
          title: 'Reports',
          type: 'collapse',
          icon: 'far fa-file-excel',
          children: [
            {
              title: 'Transaction Report',
              type: 'item',
              url: '/admin/transaction',
              icon: 'feather icon-file-text',
              // classes: 'nav-item',
            },
            // {
            //   id: 'userfunction',
            //   title: 'User Txn Report',
            //   type: 'item',
            //   url: '/admin/userFunction',
            //   icon: 'fas fa-user-cog',
            //   // classes: 'nav-item',
            // }
          ]
        },
        {
          id: 'editprofile',
          title: 'Edit Profile',
          type: 'item',
          url: '/admin/editProfile',
          icon: 'fas fa-user-edit',
          classes: 'nav-item',
        },
        {
          id: 'manualbankadd',
          title: 'Manual Bank Add',
          type: 'item',
          url: '/admin/manualbankadd',
          icon: 'feather icon-file-text',
          classes: 'nav-item',
        },
        {
          id: 'resetpassword',
          title: 'Reset Password',
          type: 'item',
          url: '/admin/resetpassword',
          icon: 'feather icon-file-text',
          classes: 'nav-item',
        }
      ]
    },
  ]
};

const NavigationItems = [
  {
    id: 'navigation',
    title: 'Navigation',
    type: 'group',
    icon: 'icon-navigation',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        url: '/admin/dashboard',
        icon: 'fas fa-tachometer-alt',
        classes: 'nav-item',
        _featureId: 'N/A'
      },
      {
        id: 'reports',
        title: 'Reports',
        type: 'collapse',
        icon: 'far fa-file-excel',
        children: [
          {
            title: 'Transaction Report',
            type: 'item',
            url: '/admin/transaction',
            icon: 'feather icon-file-text',
            _featureId: 'N/A'
            // classes: 'nav-item',
          },
            // {
            //   id: 'userfunction',
            //   title: 'User Txn Report',
            //   type: 'item',
            //   url: '/admin/userFunction',
            //   icon: 'fas fa-user-cog',
            //   _featureId: 'N/A'
            //   // classes: 'nav-item',
            // }
        ]
      },
      // {
      //   id: 'balance',
      //   title: 'Finance Function',
      //   type: 'collapse',
      //   icon: 'fas fa-wallet',
      //   children: [
      //     {
      //       id: 'addbalance',
      //       title: 'Add Balance',
      //       type: 'item',
      //       url: '/admin/balance/addbalance',
      //       icon: 'fas fa-dollar-sign',
      //       _featureId: 'N/A'
      //       // classes: 'nav-item',
      //     },
      //     {
      //       id: 'balancerequests',
      //       title: 'Balance Requests',
      //       type: 'item',
      //       url: '/admin/balance/balancerequests',
      //       icon: 'feather icon-file-text',
      //       _featureId: 'N/A'
      //       // classes: 'nav-item',
      //     },
      //     {
      //       id: 'balancerequestreports',
      //       title: 'Balance Request Reports',
      //       type: 'item',
      //       url: '/admin/balance/balancerequestreports',
      //       icon: 'feather icon-file-text',
      //       _featureId: 'N/A'
      //       // classes: 'nav-item',
      //     },
      //     {
      //       id: 'wallettopupreport',
      //       title: 'Wallet Top Up Report',
      //       type: 'item',
      //       url: '/admin/balance/wallettopupreport',
      //       icon: 'feather icon-file-text',
      //       _featureId: 'N/A'
      //       // classes: 'nav-item',
      //     },
      //     {
      //       id: 'invoice_comp',
      //       title: 'Invoice & Compliance',
      //       type: 'item',
      //       url: '/admin/balance/invoice&comp',
      //       icon: 'fa fa-list-alt',
      //       _featureId: 'N/A'
      //       // classes: 'nav-item',
      //     },
      //     {
      //       id: 'txn_clear',
      //       title: 'Txn Clear',
      //       type: 'item',
      //       url: '/admin/balance/txnclear',
      //       icon: 'feather icon-message-square',
      //       _featureId: 'N/A'
      //       // classes: 'nav-item',
      //     }
      //   ]
      // },
      // {
      //   id: 'Notification',
      //   title: 'Notification Dashboard',
      //   type: 'collapse',
      //   icon: 'fas fa-bell',
      //   children: [
      //     {
      //       id: 'Templates',
      //       title: 'Templates',
      //       type: 'item',
      //      url: '/admin/notification',
      //      icon: 'feather icon-file-text',
      //        classes: 'nav-item',
      //        _featureId: 'N/A'
      //     },   
      //   ]
      // },

      {
        id: 'member',
        title: 'Member',
        type: 'collapse',
        icon: 'fas fa-users',
        children: [
          {
            id: 'showuser',
            title: 'Show User',
            type: 'item',
            url: '/admin/member/showuser',
            icon: 'fas fa-user',
            _featureId: 'N/A'
            // classes: 'nav-item',
            
          },
          {
            id: 'createuser',
            title: 'Create User',
            type: 'item',
            url: '/admin/member/createuser',
            icon: 'fas fa-user',
            _featureId: '7'
            // classes: 'nav-item',
            
          }
       ]
      },

      // {
      //   id: 'supportfunction',
      //   title: 'Support Function',
      //   type: 'item',
      //   url: '/admin/support-func-new',
      //   icon: 'fas fa-headset',
      //   classes: 'nav-item',
      //   _featureId: 'N/A'
      // },
      // {
      //   id: 'centralize-opps',
      //   title: 'Centralize Ops',
      //   type: 'item',
      //   url: '/admin/productManage',
      //   icon: 'fas fa-tachometer-alt',
      //   _featureId: 'N/A'
      // },
      
      {

        id: 'common-onboarding',

        title: 'Common Onboarding',
        type: 'collapse',
        icon: 'feather icon-file-text',
        children: [
          {
            id: 'onboarding1',
            title: 'Agents',
            type: 'item',
            url: '/admin/commononboard/onboarding1',
            icon: 'feather icon-file-text',
            _featureId: 'N/A'
          },
          // {
          //   id: 'onboarding2',
          //   title: 'MD & Distributer',
          //   type: 'item',
          //   url: '/admin/commononboard/onboarding2',
          //   icon: 'feather icon-file-text',
          //   _featureId: 'N/A'
          // },
          
        ]
      },
    ]
  }



];

@Injectable()
export class NavigationItem {
  get() {
    return NavigationItems;
  }
}
