import {Store} from '@ngrx/store';
import { DashboardActions, DashboardActionTypes } from './actions';

export let initialDashboardState = {};

export function dashboardReducer(state = initialDashboardState, action: DashboardActions) {
    switch (action.type) {
        case DashboardActionTypes.SET_DASHBOARD_DATA:
            let dashboardData = action.payload;
            return {...state, dashboardData};
        default:
            return state;
    }
}