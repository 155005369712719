import { Component, Input, NgZone, OnInit } from '@angular/core';
import { NavigationItem } from '../../navigation';
import { DattaConfig } from '../../../../app-config';
import { Location } from '@angular/common';

@Component({
  selector: 'app-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss']
})
export class NavItemComponent implements OnInit {
  @Input() item: NavigationItem;
  public dattaConfig: any;
  public themeLayout: string;
  availableUserFeature = <any>[];
  userdata;

  constructor(private location: Location) {
    this.dattaConfig = DattaConfig.config;
    this.themeLayout = this.dattaConfig['layout'];
  }

  ngOnInit() {
    this.userdata=(JSON.parse(sessionStorage.getItem('userData')))
  }
  featureCheck(_featureId: any){
    if(_featureId === 'N/A'){return true;}
    const _currArray = [
      // {id: 4, featureName: 'onbording1', active: (JSON.parse(sessionStorage.getItem('Previlege'))).includes('AGENT_ONBOARDING')},
      // {id: 25, featureName: 'onbording2', active: !(JSON.parse(sessionStorage.getItem('Previlege'))).includes('AGENT_ONBOARDING')},
      // {id: 1, featureName: 'supportfunction', active: (JSON.parse(sessionStorage.getItem('User_Type'))).includes('ROLE_CO')},
      {id: 7, featureName: 'supportfunction', active: !((this.userdata.userInfo.userType.includes('ROLE_CIRCLE')) || (this.userdata.userInfo.userType.includes('ROLE_REGION')))}
      ];
    for(let i=0;i<_currArray.length;i++){
      if(_currArray[i].id === Number(_featureId) && _currArray[i].active){
        return true;
      }
    }
    return false;
  }

  closeOtherMenu(event) {
    if (this.dattaConfig['layout'] === 'vertical') {
      const ele = event.target;

      // Selecting the Parent Menu Item
      const collapseElement = ele.closest('.pcoded-hasmenu');
      // If it has Parent Menu, then:
      //  1. Add class 'nav-item' & 'active' to the Parent List Container
      //  2. Keep the Menu collapsed.
      if (collapseElement !== null && collapseElement !== undefined) {
        const parentListContainer = ele.closest('li');
        parentListContainer.classList.add('nav-item', 'active');

        collapseElement.classList.add('pcoded-trigger');
      } else {
          // If it does not have Parent Menu, then it is a Single NavItem, hence collapse all the Menu if uncollapsed.
          const sections = document.querySelectorAll('.pcoded-hasmenu');
          for (let i = 0; i < sections.length; i++) {
            sections[i].classList.remove('active');
            sections[i].classList.remove('pcoded-trigger');
          }
      }


      // if (ele !== null && ele !== undefined) {
      //   const parent = ele.parentElement;
      //   const up_parent = parent.parentElement.parentElement;
      //   const last_parent = up_parent.parentElement;
      //   const sections = document.querySelectorAll('.pcoded-hasmenu');
      //   for (let i = 0; i < sections.length; i++) {
      //     sections[i].classList.remove('active');
      //     sections[i].classList.remove('pcoded-trigger');
      //   }

      //   if (parent.classList.contains('pcoded-hasmenu')) {
      //     parent.classList.add('pcoded-trigger');
      //     parent.classList.add('active');
      //   } else if (up_parent.classList.contains('pcoded-hasmenu')) {
      //     up_parent.classList.add('pcoded-trigger');
      //     up_parent.classList.add('active');
      //   } else if (last_parent.classList.contains('pcoded-hasmenu')) {
      //     last_parent.classList.add('pcoded-trigger');
      //     last_parent.classList.add('active');
      //   }
      // }
      if ((document.querySelector('app-navigation.pcoded-navbar').classList.contains('mob-open'))) {
        document.querySelector('app-navigation.pcoded-navbar').classList.remove('mob-open');
      }
    } else {
      setTimeout(() => {
        const sections = document.querySelectorAll('.pcoded-hasmenu');
        for (let i = 0; i < sections.length; i++) {
          sections[i].classList.remove('active');
          sections[i].classList.remove('pcoded-trigger');
        }

        let current_url = this.location.path();
        if (this.location['_baseHref']) {
          current_url = this.location['_baseHref'] + this.location.path();
        }
        const link = "a.nav-link[ href='" + current_url + "' ]";
        const ele = document.querySelector(link);
        if (ele !== null && ele !== undefined) {
          const parent = ele.parentElement;
          const up_parent = parent.parentElement.parentElement;
          const last_parent = up_parent.parentElement;
          if (parent.classList.contains('pcoded-hasmenu')) {
            parent.classList.add('active');
          } else if (up_parent.classList.contains('pcoded-hasmenu')) {
            up_parent.classList.add('active');
          } else if (last_parent.classList.contains('pcoded-hasmenu')) {
            last_parent.classList.add('active');
          }
        }
      }, 500);
    }
  }

}
