import jwt_decode from 'jwt-decode';
export class DattaConfig {
  public static config = {
    layout: 'vertical', // vertical, horizontal
    'pre-layout': null, // null, layout-2, layout-2-2, layout-3, layout-4, layout-4-2, layout-6, layout-8
    'collapse-menu': false,
    'layout-type': 'menu-dark', // menu-dark, menu-light, dark
    'nav-icon-color': false,
    'header-back-color': 'header-default', // header-default, header-blue, header-red, header-purple, header-lightblue, header-dark
    'nav-back-color': 'navbar-default', // navbar-default, navbar-blue, navbar-red, navbar-purple, navbar-lightblue, navbar-dark
    'nav-brand-color': 'brand-default', // brand-default, brand-blue, brand-red, brand-purple, brand-lightblue, brand-dark
    'nav-back-image': false, // false, navbar-image-1, navbar-image-2, navbar-image-3, navbar-image-4, navbar-image-5
    'rtl-layout': false,
    'nav-fixed-layout': true,
    'header-fixed-layout': false,
    'box-layout': false,
    'nav-dropdown-icon': 'style1', // style1, style2, style3
    'nav-list-icon': 'style1', // style1, style2, style3, style4, style5, style6
    'nav-active-list-color': 'active-default', // active-default, active-blue, active-red, active-purple, active-lightblue, active-dark
    'nav-list-title-color': 'title-default', // title-default, title-blue, title-red, title-purple, title-lightblue, title-dark
    'nav-list-title-hide': false,
    'layout-6-background' : '#23b7e5' // used only for pre-layout = layout-6
  };
}

export class FirebaseConfig {
  public static config = {
    apiKey: "AIzaSyATxpFql9-yuh2FKtTYJSDcGp4b1unNdXE",
    authDomain: "iserveumainapp.firebaseapp.com",
    databaseURL: "https://iserveumainapp.firebaseio.com",
    projectId: "iserveumainapp",
    storageBucket: "iserveu_storage",
    messagingSenderId: "421535327263"
  }
}
export class AuthConfig {
  public static config = {
    encodeUrl: (reqUrl: string, username: string = null) => {
        if (!username) { 
          const tokenData: {sub: string} = jwt_decode(sessionStorage.getItem('CORE_SESSION')); 
          username = tokenData.sub; 
        }
        
        return new Promise<string>((res, rej) => {
      
            let bongui = new TextEncoder();
            let beetlejuice = bongui.encode("@#$jill90$=");
            crypto.subtle.importKey(
                "raw", beetlejuice,
                { name: "HMAC", hash: "SHA-256" },
                false, [ "sign" ]
               ).then((bullock) => {
                   let deffl90$ty5 = 10000
                   let expiry = Date.now() + deffl90$ty5
                   let jill = btoa(Math.round(Math.random()).toString() + Date.now()+"Uio"+ Math.round(Math.random()).toString());
                   let url = new URL(reqUrl);
                   let jojo = btoa(username);
          
                   let jojobizzare = url.pathname + expiry;
                   crypto.subtle.sign(
                    "HMAC", bullock,
                    bongui.encode(jojobizzare)
                  ).then((sec09gh7$88) => {
                    let dioadvebbt = btoa(String.fromCharCode(...new Uint8Array(sec09gh7$88)))
                    url.searchParams.set("jack", dioadvebbt)
                    url.searchParams.set("expiry", `${expiry}`)
                    url.searchParams.set('jill', jill)
                    url.searchParams.set('jojo', jojo)
          
                    // res(url.search);
                    res(url.href);
                  });
            });
        });
    }
  }
}
export class AuthConfig1 {
  public static config = {
    encodeUrl: (reqUrl: string, userName: any = null) => {        
        return new Promise<string>((res, rej) => {
      
            let bongui = new TextEncoder();
            let beetlejuice = bongui.encode("@#$jill90$=");
            crypto.subtle.importKey(
                "raw", beetlejuice,
                { name: "HMAC", hash: "SHA-256" },
                false, [ "sign" ]
               ).then((bullock) => {
                   let deffl90$ty5 = 10000
                   let expiry = Date.now() + deffl90$ty5
                   let jill = btoa(Math.round(Math.random()).toString() + Date.now()+"Uio"+ Math.round(Math.random()).toString());
                   let url = new URL(reqUrl);
                   let jojo = btoa(userName);         
                   let jojobizzare = url.pathname + expiry;
                   crypto.subtle.sign(
                    "HMAC", bullock,
                    bongui.encode(jojobizzare)
                  ).then((sec09gh7$88) => {
                    let dioadvebbt = btoa(String.fromCharCode(...new Uint8Array(sec09gh7$88)))
                    url.searchParams.set("jack", dioadvebbt)
                    url.searchParams.set("expiry", `${expiry}`)
                    url.searchParams.set('jill', jill)
                    // url.searchParams.set('jojo', jojo)
                    url.searchParams.set('userName', userName.userName)
          
                    // res(url.search);
                    res(url.href);
                  });
            });
        });
    }
  }
}

/* layout-6 background option*/
/* 1 - background color */
/*
#04a9f5,#ff5252, #9575CD, #23b7e5, #424448
linear-gradient(to right, #1de9b6 0%, #1dc4e9 100%)
linear-gradient(to right, #899FD4 0%, #A389D4 100%)
linear-gradient(to right, #4facfe 0%, #00f2fe 100%)
linear-gradient(to right, #667eea 0%, #764ba2 100%)
linear-gradient(to right, #f77062 0%, #fe5196 100%)
linear-gradient(to right, #9be15d 0%, #00e3ae 100%)
linear-gradient(to right, #b224ef 0%, #7579ff 100%)
linear-gradient(to right, #0acffe 0%, #495aff 100%)
linear-gradient(to right, #01a9ac 0%, #01dbdf 100%)
linear-gradient(to right, #fe5d70 0%, #fe909d 100%)
linear-gradient(to right, #0ac282 0%, #0df3a3 100%)
linear-gradient(to right, #fe9365 0%, #feb798 100%)
linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)
*/

/* 2 - background image */
/*
url("assets/images/bg-images/bg1.jpg")
url("assets/images/bg-images/bg3.jpg")
url("assets/images/bg-images/bg4.jpg")
url("assets/images/bg-images/bg5.jpg")
*/

/* 3 - background pattern */
/*
url("assets/images/bg-images/1.png")
url("assets/images/bg-images/2.png")
url("assets/images/bg-images/3.png")
url("assets/images/bg-images/4.png")
url("assets/images/bg-images/5.png")
url("assets/images/bg-images/6.png")
*/
