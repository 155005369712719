import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { AlertModule, BreadcrumbModule, CardModule, ModalModule } from './components';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { ImageErrorDirective } from './directives/image-error.directive';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { CamelToCapitalPipe } from './pipes/cameltocapital.pipe';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { AppDialogComponent } from './components/app-dialog/app-dialog.component';
import { ValidPinDirective } from './directives/valid-pin.directive';
import { NgxSpinnerModule } from "ngx-spinner";
import { TableModule } from "primeng/table";
import { NgbButtonsModule, NgbDropdownModule, NgbModule, NgbPopoverModule, NgbTabsetModule, NgbTooltipModule, NgbTypeaheadModule } from "@ng-bootstrap/ng-bootstrap";
import { NumberToWordsPipePipe } from './pipes/number-to-words-pipe.pipe';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { DataFilterPipe } from "./components/data-table/data-filter.pipe";
import { SpinnerComponent } from "./components/spinner/spinner.component";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    UnauthorizedComponent,
    ImageErrorDirective,
    EllipsisPipe,
    CamelToCapitalPipe,
    AppDialogComponent,
    ValidPinDirective,
    NumberToWordsPipePipe,
        DataFilterPipe,
    SpinnerComponent,
    CamelToCapitalPipe,
    ImageErrorDirective,
  ],
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    AlertModule,
    CardModule,
    BreadcrumbModule,
    ModalModule,
    DynamicDialogModule,
    NgxSpinnerModule,
    TableModule,
    NgbTooltipModule,
    NgbPopoverModule,
    CommonModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule,
    CardModule,
    BreadcrumbModule,
    ModalModule,
    NgxSpinnerModule,
    NgbTooltipModule,
    NgbPopoverModule,
    NgbTypeaheadModule,NgbButtonsModule, NgbDropdownModule, NgbTabsetModule,
    NgxDatatableModule,
    OwlDateTimeModule, OwlNativeDateTimeModule,
    NgbModule
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  exports: [
    CommonModule,
    PerfectScrollbarModule,
    AlertModule,
    CardModule,
    BreadcrumbModule,
    ModalModule,
    ImageErrorDirective,
    EllipsisPipe,
    CamelToCapitalPipe,
    DynamicDialogModule,
    AppDialogComponent,
    ValidPinDirective,
    NgxSpinnerModule,
    TableModule,
    NgbTooltipModule,
    NgbPopoverModule,
    CommonModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule,
    CardModule,
    BreadcrumbModule,
    ModalModule,
    DataFilterPipe,
    SpinnerComponent,
    NgxSpinnerModule,
    NgbTooltipModule,
    NgbPopoverModule,
    CamelToCapitalPipe,
    ImageErrorDirective,
    NgbTypeaheadModule,
    NgbButtonsModule, NgbDropdownModule, NgbTabsetModule,
    NgxDatatableModule,
    OwlDateTimeModule, OwlNativeDateTimeModule,
    NgbModule
  ]
})
export class SharedModule { }
