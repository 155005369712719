import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {DattaConfig} from '../app-config';
import {Location} from '@angular/common';
import { AdminService } from './admin.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Store } from '@ngrx/store';
import * as Dashboard from 'src/app/store/actions';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppService } from '../app.services';

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, OnDestroy{
    public dattaConfig: any;
    public navCollapsed: boolean;
    public navCollapsedMob: boolean;
    public windowWidth: number;
    userData: any;
    unsubscribeAll = new Subject();
  banner1Url: any;
  bannerToggle: boolean;
  
    constructor(private zone: NgZone, private location: Location, private adminService: AdminService, private ngxspinnerservice: NgxSpinnerService, private store: Store<any>,private appService:AppService) {
      this.dattaConfig = DattaConfig.config;
  
      let current_url = this.location.path();
      if (this.location['_baseHref']) {
        current_url = this.location['_baseHref'] + this.location.path();
      }
  
      if (current_url === this.location['_baseHref'] + '/layout/collapse-menu' || current_url === this.location['_baseHref'] + '/layout/box') {
        this.dattaConfig['collapse-menu'] = true;
      }
  
      this.windowWidth = window.innerWidth;
      this.navCollapsed = (this.windowWidth >= 992) ? this.dattaConfig['collapse-menu'] : false;
      this.navCollapsedMob = false;
    }
  
    ngOnInit() {
      this.ngxspinnerservice.show();
      this.adminService.getDashboardData();
      // this.adminService.fetchNewWalletBalance();
      // this.adminService.fetchWallet2Blanace();
      this.store.select('dashboard').pipe(takeUntil(this.unsubscribeAll)).subscribe(data => {
        this.userData = data.dashboardData;
        this.ngxspinnerservice.hide();
      });

    /*  this.appService.fetchBanner().subscribe(res=>{
        this.banner1Url=res['banner_url']['url1'];
        console.log(this.banner1Url);
        this.bannerToggle=true;

      })*/
    }
   /* closeModal(){
      this.bannerToggle=false;
        }*/
    navMobClick() {
      if (this.navCollapsedMob && !(document.querySelector('app-navigation.pcoded-navbar').classList.contains('mob-open'))) {
        this.navCollapsedMob = !this.navCollapsedMob;
        setTimeout(() => {
          this.navCollapsedMob = !this.navCollapsedMob;
        }, 100);
      } else {
        this.navCollapsedMob = !this.navCollapsedMob;
      }
    }

    ngOnDestroy() {
      this.unsubscribeAll.next(true);
      this.unsubscribeAll.complete();
    }
}