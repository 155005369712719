import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import * as vex from 'vex-js';
import { CookieService } from "ngx-cookie-service";
@Injectable({ providedIn: 'root' })
export class AutoLogOutService {

  constructor(private router: Router,private cookieService: CookieService) { }

  setToken(token: string) {
    sessionStorage.setItem('token', token);
    const { exp, sub } = jwt_decode(token);
    // Token Expire time comes in seconds, hence converted to milli seconds.
    const difference = (exp * 1000) - Date.now();
    this.startTimer(difference);
  }

  startTimer(timer: number) {
    setTimeout(() => {     
      sessionStorage.removeItem('token');
      let a= sessionStorage.getItem('logouturl')
      const url = "http://"+a+"/#/sign-in";
      window.location.replace(url);
    }, timer); // Logout after timer seconds
  }

  logout() {
    let a= sessionStorage.getItem('logouturl')
    window.removeEventListener('offline', this.handleOffline, true); 
    console.clear(); // Clear the Console.
    vex.closeAll(); // Close all vex dialogs
    sessionStorage.clear();    
    const url = "http://"+a+"/#/sign-in";
    console.log(url);    
    window.location.replace(url);
    vex.closeAll(); // Close all vex dialogs
    sessionStorage.removeItem('token');
    // this.router.navigate(['/']);
    sessionStorage.clear()
  }
  handleOffline = (evt) => {
    console.log('Internet Disconnected.')
  }

}
