import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';
import * as XLSX from 'xlsx';
import jwt_decode from 'jwt-decode';
import * as Dashboard from 'src/app/store/actions';
import { finalize } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { AutoLogOutService } from '../autologout.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Loading } from 'notiflix';
import * as Notiflix from 'notiflix';
import { Config, error } from 'protractor';
import { environment } from './../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class AdminService {
    walletBalance = new BehaviorSubject(0);
    userBalance2 = new BehaviorSubject(0);
    testArrayJsonData = [{ A: "S", B: "h", C: "e", D: "e", E: "t", F: "J", G: "S" }];
    constructor(private http: HttpClient, private store: Store<any>, private ngxSpinner: NgxSpinnerService, private autologoutservice: AutoLogOutService, private toastr: ToastrService) { }

    getDashboardData() {
        Loading.dots();
        this.http.get(environment.dashboardApiBaseUrl + '/user/dashboard') //Prod
            .pipe()
            .subscribe(
                (data: any) => {
                    Notiflix.Loading.remove();
                    sessionStorage.setItem('userData', JSON.stringify(data));
                    sessionStorage.setItem('Previlege', JSON.stringify(data.userInfo.privileges));
                    this.store.dispatch(new Dashboard.SetDashboardData(data));
                    if (data.userInfo.userType == 'ROLE_AGENT' || data.userInfo.userType == 'ROLE_RETAILER') {
                        this.autologoutservice.logout();
                    }
                    else {
                        sessionStorage.setItem('adminName', data.userInfo.adminName);
                        if (sessionStorage.getItem('access_token') !== null) {
                            let a = Number(sessionStorage.getItem('counter'))
                            if (a == 1) {
                                this.toastr.success('Logged in succesfully', '', { positionClass: 'toast-top-left' });
                                sessionStorage.setItem('counter', (a + 1).toString())
                            }
                        }
                    }
                },
                () => {
                    Notiflix.Loading.remove();
                    this.autologoutservice.logout();
                }
                // {next: (v: any) => {  
                //     Notiflix.Loading.remove();
                //     sessionStorage.setItem('userData', JSON.stringify(v));
                //     sessionStorage.setItem('Previlege',JSON.stringify(v.userInfo.privileges));                     
                //     this.store.dispatch(new Dashboard.SetDashboardData(v));
                //     if(v.userInfo.userType=='ROLE_AGENT' ||v.userInfo.userType=='ROLE_RETAILER'){
                //     this.autologoutservice.logout();
                //     }
                //     },  
                //     error(err) {debugger
                //         Notiflix.Loading.remove();
                //         console.log(err); 
                //         // this.toastr.error('Problem In Server', '', { positionClass: 'toast-top-left' });               
                //         this.autologoutservice.logout();
                //     },          
                //     complete: () => {debugger
                //     },
                // }  
            );
    }
    uaepsData(uaepsData: any) {
        // return this.http.post('https://aepsinternalreport-vn3k2k7q7q-el.a.run.app/internal',uaepsData);
        return this.http.post('https://aepsinternalreportstaging-zwqcqy3qmq-uc.a.run.app/internal', uaepsData);
    }
    adharpayData(adharpayData: any) {
        return this.http.post('https://unifiedinternalreportallstaging-zwqcqy3qmq-uc.a.run.app/all_internal', adharpayData);
    }
    adharpayregistrationData(adharpayregistrationData: any) {
        return this.http.post('https://secure.iserveu.tech/matm/fetchExcelData', adharpayregistrationData);
    }

    aeps2Data(aeps2Data: any) {
        return this.http.post('https://aeps2internalstaging-zwqcqy3qmq-uc.a.run.app/aeps2_internal', aeps2Data);
        //  return this.http.post('https://aeps2internalstaging-zwqcqy3qmq-rj.a.run.app/aeps2_internal',aeps2Data);
        //  return this.http.post('https://internalreport-vn3k2k7q7q-uc.a.run.app/all',aeps2Data);
    }

    Matm2Data(Matm2Data: any) {
        // return this.http.post('https://matm2internalprodstaging-zwqcqy3qmq-uc.a.run.app/matm2_internal_report', Matm2Data);//staging url pointed to prod
        return this.http.post(environment.mATMReportApiUrl+'/ind_matm', Matm2Data); //Prod URL
    }
    BBPSData(BBPSData: any) {
        return this.http.post('https://newbbps-internal-vn3k2k7q7q-uc.a.run.app/bbps_internal', BBPSData); //Prod URL
    }
    LICData(LICData: any) {
        return this.http.post('https://lic-internal-prod-vn3k2k7q7q-uc.a.run.app/lic_internal', LICData); //Prod URL
    }

    Wallet2cashOut(WALLET2CASHOUT: any) {
        // return this.http.post('https://newwallet2cashoutinternalstaging-zwqcqy3qmq-uc.a.run.app/WALLET2CASHOUT_report', WALLET2CASHOUT);
        return this.http.post('https://newwallet2cashoutinternalprod-vn3k2k7q7q-uc.a.run.app/wallet2cashout_report', WALLET2CASHOUT);
    }

    upiData(upiData: any) {
        return this.http.post('https://internalreport-vn3k2k7q7q-uc.a.run.app/all', upiData);
    }

    posData(posData: any) {
        return this.http.post('https://pos-internal-prod-vn3k2k7q7q-uc.a.run.app/pos_internal', posData);
    }
    poscashoutData(poscashoutData: any) {
        return this.http.post('https://pos-internal-prod-vn3k2k7q7q-uc.a.run.app/pos_internal', poscashoutData);
    }
    poswalletData(poswalletData: any) {
        return this.http.post('https://pos-internal-prod-vn3k2k7q7q-uc.a.run.app/pos_internal', poswalletData);
    }


    getDemoReport(reportData: any) {
        return this.http.post(environment.walletReportApiUrl+'/ind_matm', reportData);
    }


    newDmtReport(reportData: any) {
        return this.http.post('https://unified-new-dmt-internal-prod-vn3k2k7q7q-uc.a.run.app/new_dmt_internal', reportData);

    }

    newCommission(reportData: any) {
        return this.http.post(environment.commissionReportApiUrl+'/ind_matm', reportData);
    }

    newWallet(reportData: any) {
       return this.http.post('https://unified-new-dmt-internal-prod-vn3k2k7q7q-uc.a.run.app/new_dmt_internal', reportData);//prod url
    }
    newrecharge(reportData: any) {
        //   return this.http.post('https://newinternalalltxn-vn3k2k7q7q-uc.a.run.app/all_internal',reportData) //production
        return this.http.post('https://newinternalalltxn.iserveu.tech/all_internal', reportData) //production
        // return this.http.post('https://newinternalalltxn-zwqcqy3qmq-uc.a.run.app/all_internal',reportData) //stagging

    }

    /* Date Modifier */
    public dateMod(key) {
        const dateOptions = { dateStyle: 'short', timeStyle: 'short', hour12: true };
        let formatted_date = key ? new Date(key).toLocaleString('en-US', dateOptions) : '';
        console.log(key);
        return formatted_date;
    }

    /* Id Modifier */
    private numberMod(key: any) {
        const _keyarr = String(key).split('');
        let formatted_id = key ? _keyarr.includes('#') ? key : `#${key}` : '';
        return formatted_id;
    }

    generateExcel(data: Array<any> = this.testArrayJsonData, type: string) {
        const formattedData = data.map(data => {
            const frecord = { ...data };
            if (frecord.Id) { frecord.Id = this.numberMod(frecord.Id); }
            if (frecord.relationalId) { frecord.relationalId = this.numberMod(frecord.relationalId); }
            if (frecord.gatewayId) { frecord.gatewayId = this.numberMod(frecord.gatewayId); }
            if (frecord.refundId) { frecord.refundId = this.numberMod(frecord.refundId); }
            if (frecord.createdDate) { frecord.createdDate = frecord.createdDate; }
            if (frecord.updatedDate) { frecord.updatedDate = frecord.updatedDate; }
            if (frecord.depositDateTime) { frecord.depositDateTime = this.dateMod(frecord.depositDateTime); }
            if (frecord.requestDateTime) { frecord.requestDateTime = this.dateMod(frecord.requestDateTime); }
            if (frecord.approvalTime) { frecord.approvalTime = this.dateMod(frecord.approvalTime); }
            if (frecord.gateWayData) { frecord.gateWayData = (typeof frecord.gateWayData === 'object') ? frecord.gateWayData.map(data => JSON.stringify(data)).join(', ') : frecord.gateWayData; }
            return frecord;
        });
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(formattedData);
        const wb: XLSX.WorkBook = { Sheets: { Sheet1: ws }, SheetNames: ['Sheet1'] };
        XLSX.writeFile(wb, `${type}.csv`);
    }


    /* fetch new wallet balance */
    fetchNewWalletBalance() {
        const tokenData: { sub: string } = jwt_decode(sessionStorage.getItem('token'));
        // this.http.post('https://grpcwallet-vn3k2k7q7q-uc.a.run.app/wallet/checkbalance',{user_name: tokenData.sub}) // Staging
        this.http.post('https://grpcwalletprod-vn3k2k7q7q-uc.a.run.app/wallet/checkbalance', { user_name: tokenData.sub }) // Production
            .pipe(finalize(() => { this.ngxSpinner.hide('new-wallet-spinner'); }))
            .subscribe(
                (res: any) => {
                    console.log('Wallet Balance Response: ', res);
                    this.walletBalance.next(res.response.Balance); // Update Wallet Balance.
                },
                (err: any) => {
                    console.log('Wallet Balance Error: ', err);
                    // vex.dialog.alert(err.error.errorMessage);
                    this.walletBalance.next(0);
                });
    }

    /* fetch W2 balance */
    // fetchWallet2Blanace() {
    //     this.http.get('https://secure.iserveu.tech/user/getuserbalance') // production
    //         // this.http.get('https://uatapps.iserveu.online/core_bs/user/wallet2/getuserbalance') //staging
    //         .pipe(finalize(() => { this.ngxSpinner.hide('wallet2Spinner'); }))
    //         .subscribe(
    //             (res: any) => {
    //                 console.log('Wallet 2 Balance Response: ', res);
    //                 this.userBalance2.next(res);
    //             },
    //             (err: any) => {
    //                 console.log('Wallet 2 Balance Error: ', err);
    //                 this.userBalance2.next(0);
    //             }
    //         )
    // }

}