import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthConfig } from "./app-config";

@Injectable({
  providedIn: 'root'
})
export class AppService {
  constructor(private http: HttpClient) { }
  forgotPasswordSendTemporaryPassword(body: { userName: string, otp: string }) {
    // return this.http.post('https://userprivilege.iserveu.tech//utility/forgot-password-send-temporary-password', body);
    return this.http.post('https://user-registration-uat-stag.iserveu.tech/utility/verify-otp-send-temporary-password', body);
  }

  sendForgotPpasswordOtp(url, body) {
    // return this.http.post('https://userprivilege.iserveu.tech/utility/send-forgot-password-otp', body);
    return this.http.post(url, body);
  }

  clientLogin(loginCredentials: { username: string, password: string }) {
    // return this.http.post('https://userprivilege-uat-twdwtabx5q-el.a.run.app/getlogintoken', loginCredentials);
    return this.http.post('https://userprivilege.iserveu.tech/getlogintoken', loginCredentials);
  }
  bannerUpload(obj){
   // return this.http.post("https://banneruploadstaging-vn3k2k7q7q-uc.a.run.app/bm/upload_banner_url",obj);
    return this.http.post("https://banneruploadprod-vn3k2k7q7q-uc.a.run.app/bm/upload_banner_url",obj);
  }
  fetchBanner(){

    let body={
        "admin_name" : "iserveu"
    }
    
   // return this.http.post("https://banneruploadstaging-vn3k2k7q7q-uc.a.run.app/bm/fetch_banner",body);
  // return this.http.post("https://banneruploadprod-vn3k2k7q7q-uc.a.run.app/bm/fetch_banner",body);
  return this.http.post("https://banneruploadprod-vn3k2k7q7q-uc.a.run.app/bm/fetch_banner",body);
  }

  removeBanner(bannerObj){
  console.log(bannerObj);
   // return this.http.post("https://banneruploadstaging-vn3k2k7q7q-uc.a.run.app/bm/delete_banner",bannerObj);
   return this.http.post("https://banneruploadprod-vn3k2k7q7q-uc.a.run.app/bm/delete_banner",bannerObj);
  }
  async Gotodashboard(){
    var url = window.location.href;
    console.log(url);
    var urlArr = url.split("/");
    var urlArrLen = urlArr.length;
    var params = urlArr[urlArrLen-1].split('&');
    if(!sessionStorage.getItem('access_token')){
      if(params.length>0){
      sessionStorage.setItem('access_token', atob(params[0]));
      sessionStorage.setItem('refresh_token', atob(params[1]));
      sessionStorage.setItem('exp', atob(params[2]));
      sessionStorage.setItem('logouturl', atob(params[3]));
      } 
    }
    
  }
  observeInternetConn() {
    console.log('Checking Internet Offline Status');    
    window.addEventListener('offline', this.handleOffline, true);
  }
  handleOffline = (evt) => {
    console.log('Internet Disconnected.')
    // console.log('Internet Disconnected Event: ', evt);
    // Disconnect Socket, if internet disconnected
    // vex.dialog.alert('Internet Disconnected');
  }
}
