<!-- <h2>AdminComponent works</h2> -->

<!-- <app-navigation></app-navigation>
<app-navbar></app-navbar>
<router-outlet></router-outlet> -->



<ng-container *ngIf="userData">
  <app-navigation class="pcoded-navbar" [userData]="userData"
    [ngClass]="{'navbar-collapsed' : navCollapsed, 'custom-nav' : !navCollapsed, 'theme-horizontal': this.dattaConfig['layout'] === 'horizontal', 'mob-open' : navCollapsedMob}"
    (onNavCollapse)="navCollapsed = !navCollapsed;" (onNavCollapsedMob)="navMobClick()"></app-navigation>
  <app-nav-bar class="navbar pcoded-header navbar-expand-lg navbar-light" [ngClass]="{'custom-navbar': !navCollapsed, 'custom-navbar-mob': navCollapsed}" (onNavCollapsedMob)="navMobClick()" [userData]="userData">
  </app-nav-bar>
  <div class="pcoded-main-container" [ngClass]="{'custom-container': !navCollapsed, 'custom-container-mob': navCollapsed}">
    <div class="pcoded-wrapper">
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <router-outlet></router-outlet>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--<div *ngIf="bannerToggle==true">
    <div  class="slide-modal">
      <span class="close cursor" (click)="closeModal()"> <i class="fas fa-times-circle"></i></span>
     
      <img src={{banner1Url}}>
     
      </div>
      </div>-->
</ng-container>

<!-- <div *ngIf="bannerToggle==true">
  <div  class="slide-modal">
    <span class="close cursor" (click)="closeModal()"> <i class="fas fa-times-circle"></i></span>
   
    <img src={{banner1Url}}>
   
    </div>
    </div> -->

<ngx-spinner></ngx-spinner>
