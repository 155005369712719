import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { AdminComponent } from "./admin.component";
import { DashboardModule } from './dashboard/dashboard.module';
import { NavLeftComponent } from './nav-bar/nav-left/nav-left.component';
import { NavSearchComponent } from './nav-bar/nav-left/nav-search/nav-search.component';
import { NavRightComponent } from './nav-bar/nav-right/nav-right.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { NavCollapseComponent } from './navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavGroupComponent } from './navigation/nav-content/nav-group/nav-group.component';
import { NavItemComponent } from './navigation/nav-content/nav-item/nav-item.component';
import { NavContentComponent } from './navigation/nav-content/nav-content.component';
import { NavigationComponent } from './navigation/navigation.component';
import { NavLogoComponent } from './navigation/nav-logo/nav-logo.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PaginationComponent } from './pagination/pagination.component';
import { DatePipe } from '@angular/common';

// import { ActiveInactiveComponent } from './active-inactive/active-inactive.component';
// import { CentralizeproductManageComponent } from './centralizeManage/centralizeproduct-manage/centralizeproduct-manage.component';

@NgModule({
  declarations: [
    AdminComponent,
    NavigationComponent,
    NavBarComponent,
    NavLogoComponent,
    NavContentComponent,
    NavCollapseComponent,
    NavGroupComponent,
    NavItemComponent,
    NavSearchComponent,
    NavLeftComponent,
    NavRightComponent,
   // PaginationComponent,
  ],
  imports: [
    RouterModule,
    SharedModule,
    DashboardModule,
    NgbDropdownModule,
    NgxSpinnerModule,
  ],
  providers: [DatePipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AdminModule { }
