import { Component } from '@angular/core';
import * as vex from 'vex-js';
import * as vexDialog from 'vex-dialog';
import {NavigationEnd, Router} from '@angular/router';
import { AppService } from './app.services';
import { AutoLogOutService } from './autologout.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'basicapp';

  constructor(private router: Router,private appService: AppService, private logout:AutoLogOutService){
    // Registering Vex Dialog Plugin only once through out the project
    vex.registerPlugin(vexDialog);
    vex.defaultOptions.className = 'vex-theme-default';
  }
  async ngOnInit() {
    await this.appService.Gotodashboard();     
    if (sessionStorage.getItem('access_token')) { 
      this.appService.observeInternetConn(); // Observe Internet Connection
    } else {            
        this.logout.logout();
    }
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });    
  }
}
