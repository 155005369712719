<ul class="navbar-nav">
  <!-- <li>
    <div placement='auto'>
      <a href="javascript:" (click)="fetchWalletBalance()">
        <i class="fas fa-wallet"><span> <b>W1:</b> {{userBalance | currency:"INR":"symbol"}}</span></i>
      </a>
    </div>
  </li>
  <li>
    <div placement='auto'>
      <a href="javascript:" (click)="fetchWalletBalance2()">
        <i class="fas fa-wallet"><span> <b>W2:</b> {{userBalance2 | currency:"INR":"symbol"}}</span></i>
      </a>
    </div>
  </li> -->
  <li>
    <div class="dropdown drp-user" ngbDropdown placement='auto'>
      <a href="javascript:" ngbDropdownToggle data-toggle="dropdown">
        <i class="icon feather icon-settings"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-right profile-notification" ngbDropdownMenu>
        <div class="pro-head">
          <img imageError src="assets/images/indiapost.png">
          <span>{{userData.userInfo.userName}}</span>
        </div>
        <ul class="pro-body">
          <li><a href="javascript:" class="dropdown-item"><i class="feather icon-user"></i> Profile</a></li>
          <li><a [routerLink]="['/admin/settings/reset']"  href="javascript:" class="dropdown-item"><i class="fa fa-key"></i> Change Password</a></li>
          <!-- <li><a [routerLink]="['/admin/settings']" href="javascript:" class="dropdown-item"><i class="feather icon-settings"></i> Settings</a></li> -->
          <!-- <li><a href="javascript:" class="dropdown-item" (click)="logoutSession()"><i class="feather icon-mail"></i> My Messages</a></li>
          <li><a href="javascript:" class="dropdown-item" (click)="logoutSession()"><i class="feather icon-lock"></i> Lock Screen</a></li> -->
          <li><a href="javascript:" class="dropdown-item" (click)="logoutSession()"><i class="feather icon-log-out"></i> Logout</a></li>
        </ul>
      </div>
    </div>
  </li>
</ul>
<ngx-spinner name="new-wallet-spinner"><p style="color: white; margin-top: 50px;" > Please Wait, Fetching Wallet1 Balance... </p></ngx-spinner>
<ngx-spinner name="wallet2Spinner"><p style="color: white; margin-top: 50px;" > Please Wait, Fetching Wallet2 Balance... </p></ngx-spinner>
