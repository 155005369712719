import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: '[validPIN]'
})
export class ValidPinDirective {
  constructor(private el: ElementRef) { }

  @HostListener('keypress', ['$event']) validatePin(e: any) {
    if (e.charCode < 48 || e.charCode > 57) { return false; }
    if (this.el.nativeElement.value.length >= 6) { return false; }
  }
}
