import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AutoLogOutService } from './autologout.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(private autologoutservice: AutoLogOutService) { }
  intercept(req: HttpRequest<HttpEvent<any>>, next: HttpHandler): Observable<any> {
    // console.log(req); debugger;
    if (req.url.endsWith('/oauth/token')) {
      // req = req.clone({
      //   setHeaders: {
      //     'Content-Type': 'application/x-www-form-urlencoded',
      //     Authorization: `Basic `+ btoa('isu-client:isu-password')
      //   }
      // });
    }else{
    if (req.url.endsWith('isu_bank/_search') || req.url.endsWith('user_elastic/_search') || req.url.endsWith('isu_elastic_user/_search') || req.url.endsWith('elastic_users/_search') || req.url.endsWith('isu_bank_stagging/_search')) {
      req = req.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          Authorization: 'Basic ZWxhc3RpYzpUQWhJamJ4U2RzRzRRRDY3WWVmZTZQdzg='
        }
      });
    }
    if (sessionStorage.getItem('CORE_SESSION')) {
      if (req.url.endsWith('isu_bank/_search') || req.url.endsWith('user_elastic/_search') || req.url.endsWith('isu_elastic_user/_search') || req.url.endsWith('isu__bank_stagging/_search') || req.url.endsWith('isu_ippb_user/_search') || req.url.endsWith('ippb_users/_search') ) {
        req = req.clone({
          setHeaders: {
            'Content-Type': 'application/json',
            Authorization: 'Basic ZWxhc3RpYzpUQWhJamJ4U2RzRzRRRDY3WWVmZTZQdzg='
          }
        });
      } else {
        req = req.clone({
          headers: req.headers.set('Authorization', `${sessionStorage.getItem('CORE_SESSION')}`)
        });
      }
    }
  }
    return next.handle(req)
    .pipe(
      tap(evt => {
        if ((evt instanceof HttpResponse)) {
          this.autologoutservice.setToken(sessionStorage.getItem('refresh_token'));
        }
      })
    );
  }
}
