<div class="dialogInfoType" *ngIf="this.dialogData.type === 'info'">
  <p>{{dialogData.msg}}</p>
</div>

<div *ngIf="this.dialogData.type === 'vbd'" class="vbdContainer">
  <div>
    Wallet1: {{dialogData.vbd.wallet1}}
  </div>
  <div>
    Wallet2: {{dialogData.vbd.wallet2}}
  </div>
</div>

<!-- <div class="dialogFooter">
  <button type="button" class="btn btn-rounded btn-dark shadow-3" (click)="closeDialog()">OK</button>
</div> -->