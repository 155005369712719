import { NgModule } from '@angular/core';

import { AuthComponent } from "./auth.component";
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AlertComponent } from '../shared/components/alert/alert.component';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
  },
  {
    path: 'reset-password',
    component: AlertComponent,
  }
]

@NgModule({
  declarations: [AuthComponent],
  imports: [RouterModule.forChild(routes), FormsModule, ReactiveFormsModule, CommonModule, NgxSpinnerModule, AutoCompleteModule],
  exports: [RouterModule],

})
export class AuthModule { }
