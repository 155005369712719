import {Action} from '@ngrx/store';

export enum DashboardActionTypes {
    SET_DASHBOARD_DATA = 'SET_DASHBOARD_DATA'
}

export class SetDashboardData implements Action {
    readonly type = DashboardActionTypes.SET_DASHBOARD_DATA;
    constructor(public payload: any) {}
}

export type DashboardActions = SetDashboardData;